"use client";
import { usePathname, useSearchParams } from "next/navigation";
import { type ReactNode, useEffect, useRef } from "react";
import styles from "./Dialog.module.css";

interface DialogProps {
  children: ReactNode;
  searchParam: string;
}

export function Dialog({ children, searchParam }: DialogProps) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const dialogRef = useRef<null | HTMLDialogElement>(null);
  const showDialog = searchParams.get(searchParam);

  const closeDialog = () => {
    dialogRef.current?.close();

    const routeTimer = setTimeout(() => {
      if (typeof window !== "undefined") {
        window.history.replaceState(null, "", pathname);
      }
    }, 300);
    return () => clearTimeout(routeTimer);
  };

  useEffect(() => {
    if (!showDialog) {
      dialogRef.current?.close();
      document.body.style.overflow = "unset";
      return;
    }
    document.body.style.overflow = "hidden";
    dialogRef.current?.show();
  }, [showDialog]);

  return (
    <>
      {showDialog === "opened" && (
        <dialog className={styles.dialog} ref={dialogRef}>
          <div className={`container ${styles.diaglogContent}`}>
            <button
              type="button"
              className={styles.closeButton}
              onClick={closeDialog}
            >
              <svg
                role="img"
                aria-label="close-button"
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 33L33 9"
                  stroke="#444444"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M33 33L9 9"
                  stroke="#444444"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {children}
          </div>
        </dialog>
      )}
    </>
  );
}
