"use client";

import { valibotResolver } from "@hookform/resolvers/valibot";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { sendFormAction } from "src/actions/sendForm";
import { type SendFormInputs, SendFormSchema } from "src/schema/validation";
import { Br } from "../ui/br";
import { nbsp } from "../ui/nbsp";
import styles from "./SendForm.module.css";

const privacy = "/privacy.pdf";

export function SendForm() {
  const [isHovered, setHovered] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isLoading, isSubmitSuccessful },
  } = useForm<SendFormInputs>({ resolver: valibotResolver(SendFormSchema) });

  const onSubmit: SubmitHandler<SendFormInputs> = (formData) => {
    sendFormAction(formData);
    reset();
  };

  return (
    <main className={styles.sendForm}>
      <title>Обсудить проект</title>
      <h2>
        Оставьте контакты <br /> и{nbsp}мы{" "}
        <span>
          с{nbsp}вами <Br isMobile={true} /> свяжемся
        </span>
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.field}>
          <input {...register("name")} type="text" placeholder="Поле" />
          <label htmlFor="">Как вас зовут?</label>
          {errors.name && (
            <div className={styles.error}>{errors.name.message}</div>
          )}
        </div>
        <div className={styles.field}>
          <input {...register("contact")} type="text" placeholder="Поле" />
          <label htmlFor="">Номер телефона \ Email \ Telegram</label>
          {errors.contact && (
            <div className={styles.error}>{errors.contact.message}</div>
          )}
        </div>
        <div className={styles.field}>
          <textarea {...register("message")} rows={1} placeholder="Поле" />
          <label htmlFor="">Опишите задачу</label>
          {errors.message && (
            <div className={styles.error}>{errors.message.message}</div>
          )}
        </div>
        <div className={styles.checkbox}>
          <label htmlFor="">
            <input
              {...register("checkbox")}
              aria-invalid={!!errors.checkbox}
              aria-errormessage={`${errors.checkbox?.message}-error`}
              type="checkbox"
            />
            <p>
              Я прочитал 
              <a href={privacy} target="_blank" rel="noreferrer">
                политику обработки <Br isMobile={true} /> персональных данных
              </a>
               и 
              <a href={privacy} target="_blank" rel="noreferrer">
                даю согласие <Br isMobile={true} /> на обработку своих данных
              </a>
            </p>
          </label>
          {errors.checkbox && (
            <div className={styles.error}>{errors.checkbox.message}</div>
          )}
        </div>
        <button
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={{
            border: "0.125rem solid var(--clr-current)",
            backgroundColor: isHovered ? "var(--clr-current)" : "transparent",
          }}
          disabled={isLoading}
          className={styles.submit}
          type="submit"
        >
          Отправить
        </button>
        {isSubmitSuccessful && (
          <div className={styles.success}>Ваша заявка успешно отправлена!</div>
        )}
      </form>
    </main>
  );
}
