"use client";

import { useMousePosition } from "@/hooks/useMousePosition";
import { useWindowSize } from "@/hooks/useWindowSize";
import { motion } from "motion/react";
import { type MutableRefObject, useEffect, useRef, useState } from "react";
import { useColorContext } from "src/context/color-context";
import { useCursorVariantContext } from "src/context/cursor-variant-context";
import styles from "./Cursor.module.css";

const contentVariants = {
  default: {
    scale: 0,
    opacity: 0,
  },
  link: {
    scale: 1,
    opacity: 1,
  },
  footer: {
    scale: 0,
    opacity: 0,
  },
  hidden: {
    scale: 0,
    opacity: 0,
  },
};

export function Cursor() {
  const [isVisible, setIsVisible] = useState(true);
  const [lastMouseMoveTime, setLastMouseMoveTime] = useState(Date.now());

  const cursor = useRef() as MutableRefObject<HTMLDivElement>;
  const cursorContent = useRef() as MutableRefObject<HTMLDivElement>;
  const mousePosition = useMousePosition();
  const { cursorVariant } = useCursorVariantContext();
  const { currentColor } = useColorContext();
  const { desktopWidth } = useWindowSize();

  const variants = {
    default: {
      x: mousePosition.x + 30,
      y: mousePosition.y + 30,
    },
    link: {
      x: mousePosition.x,
      y: mousePosition.y,
      height: 60,
      width: 60,
    },
    footer: {
      x: mousePosition.x + 30,
      y: mousePosition.y + 30,
      height: 10,
      width: 10,
      backgroundColor: "var(--clr-white)",
    },
    hidden: {
      opacity: 0,
      x: mousePosition.x + 30,
      y: mousePosition.y + 30,
    },
  };

  useEffect(() => {
    const handleMouseMove = () => {
      setLastMouseMoveTime(Date.now());
      if (cursorVariant !== "link") {
        // <-- Условие для cursorVariant
        setIsVisible(true);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    const timer = setInterval(() => {
      if (cursorVariant !== "link" && Date.now() - lastMouseMoveTime > 100) {
        setIsVisible(false);
      }
    }, 1000); // Проверка каждую секунду

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      clearInterval(timer);
    };
  }, [lastMouseMoveTime, cursorVariant]);

  if (!desktopWidth) {
    return null;
  }

  return (
    <motion.div
      variants={variants}
      animate={isVisible || cursorVariant === "link" ? cursorVariant : "hidden"}
      transition={{ ease: "backOut", bounce: true, duration: 0.5 }}
      ref={cursor}
      id={"custom-cursor"}
      className={styles.customCursor}
      style={{ backgroundColor: currentColor }}
    >
      <motion.span
        variants={contentVariants}
        animate={cursorVariant}
        transition={{ duration: 0.3 }}
        ref={cursorContent}
        className={styles.cursorContent}
      >
        <svg
          role="img"
          aria-label="arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 26 26"
          fill="none"
        >
          <path
            d="M19.6526 16.0516L19.6526 7.12919L10.7302 7.12919"
            stroke="white"
            strokeWidth="0.668182"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.15402 19.6233L19.5234 7.25391"
            stroke="white"
            strokeWidth="0.668182"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </motion.span>
    </motion.div>
  );
}
