__turbopack_context__.v({
  "active": "Header-module__ldgnoG__active",
  "burgerButton": "Header-module__ldgnoG__burgerButton",
  "header": "Header-module__ldgnoG__header",
  "headerDiscussButton": "Header-module__ldgnoG__headerDiscussButton",
  "headerMobileDiscussButton": "Header-module__ldgnoG__headerMobileDiscussButton",
  "logo": "Header-module__ldgnoG__logo",
  "navLi": "Header-module__ldgnoG__navLi",
  "navLink": "Header-module__ldgnoG__navLink",
  "navLinkBg": "Header-module__ldgnoG__navLinkBg",
  "navLinks": "Header-module__ldgnoG__navLinks",
  "navLinksList": "Header-module__ldgnoG__navLinksList",
});
