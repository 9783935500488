"use client";

import { useState, useEffect } from "react";

function getWindowDimensions() {
	if (typeof window === "undefined") {
		const width = 1920;
		const maxWidth = 1024;
		const desktopWidth = width > maxWidth;
		return {
			desktopWidth,
		};
	}
	const { innerWidth: width, innerHeight: height } = window;
	const maxWidth = 1024;
	const desktopWidth = width > maxWidth;

	return {
		maxWidth,
		desktopWidth,
		width,
		height,
	};
}

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowSize(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
}
