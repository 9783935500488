"use client";

import type { ReactNode } from "react";
import styles from "../Footer.module.css";
import { useCursorVariantContext } from "src/context/cursor-variant-context";

export function FooterWrapper({ children }: { children: ReactNode }) {
	const { linkEnterFooter, linkLeave } = useCursorVariantContext();

	return (
		<footer
			onMouseEnter={() => linkEnterFooter()}
			onMouseLeave={() => linkLeave()}
			onClick={() => linkLeave()}
			{...linkLeave}
			id={"contacts"}
			className={styles.footer}
		>
			{children}
		</footer>
	);
}
