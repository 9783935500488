import * as v from "valibot";

export const SendFormSchema = v.object({
	name: v.pipe(
		v.string("Имя должно быть из букв"),
		v.nonEmpty("Пожалуйста, заполните имя"),
		v.minLength(3, "Слишком короткое имя"),
		v.maxLength(50, "Слишком длинное имя"),
	),
	contact: v.pipe(
		v.string(),
		v.nonEmpty("Пожалуйста, заполните контакты"),
		v.minLength(3, "Контакт должен иметь более 3 символов"),
		v.maxLength(100, "Контакт не должен превышать 100 символов"),
	),
	message: v.pipe(
		v.string(),
		v.nonEmpty("Пожалуйста, опишите свою задачу"),
		v.maxLength(300, "Длина сообщения не должна превышать 300 символов"),
	),

	checkbox: v.literal(true, "Необходимо согласиться с условиями"),
});

export type SendFormInputs = v.InferInput<typeof SendFormSchema>;
