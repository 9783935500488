"use client";

import { DiscussButton } from "@/components/ui/buttons";
import { useWindowSize } from "@/hooks/useWindowSize";
import NTMEDIALogo from "@/images/logo.svg";
import { links } from "@/lib/data";
import { RoutesEnum } from "@/lib/routes";
import { motion, useMotionValueEvent, useScroll } from "motion/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import styles from "./Header.module.css";

export function Header() {
  const { scrollY } = useScroll();
  const [isHidden, setIsHidden] = useState(false);
  const [prevScroll, setPrevScroll] = useState(0);
  const [openedBurger, setOpenedBurger] = useState(false);
  const { desktopWidth } = useWindowSize();
  const pathname = usePathname();
  const [activeSection, setActiveSection] = useState(() => {
    const currentLink = links.find((link) => link.hrefLink === pathname);
    return currentLink ? currentLink.name : "Главная";
  });

  const animateBurger = openedBurger
    ? { x: 0, opacity: 1 }
    : !openedBurger && { x: "100%", opacity: 0 };

  function handleOpenBurger() {
    setOpenedBurger(!openedBurger);
  }

  function update(latest: number, prev: number): void {
    if (latest < prev) {
      setIsHidden(false);
    } else if (latest > 100 && latest > prev) {
      setIsHidden(true);
    }
  }

  useMotionValueEvent(scrollY, "change", (latest: number) => {
    update(latest, prevScroll);
    setPrevScroll(latest);
  });

  const headerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  useEffect(() => {
    if (openedBurger) {
      document.body.style.overflow = "hidden";
    }
    if (!openedBurger) {
      document.body.style.overflow = "visible";
    }
  }, [openedBurger]);

  return (
    <motion.header
      style={{ visibility: isHidden ? "hidden" : "visible" }}
      className={styles.header}
      variants={headerVariants}
      animate={isHidden ? "hidden" : "visible"}
      transition={{ duration: 0.3, ease: "easeIn" }}
    >
      <Link
        href={RoutesEnum.MAIN}
        onClick={() => setActiveSection(links[0].name)}
      >
        <Image
          src={NTMEDIALogo}
          aria-label="ntmedia-logo"
          className={styles.logo}
          alt={"ntmedia"}
          priority={true}
        />
      </Link>
      <motion.nav
        className={styles.navLinks}
        initial={{ y: -100, opacity: 0 }}
        animate={desktopWidth ? { y: 0, opacity: 1 } : animateBurger}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        <ul className={styles.navLinksList}>
          {links.map((link) => {
            return (
              <motion.li key={link.name} className={styles.navLi}>
                <Link
                  href={link.hrefLink}
                  className={`${styles.navLink} ${
                    activeSection === link.name && styles.active
                  }`}
                  onClick={() => {
                    setActiveSection(link.name);
                    openedBurger && handleOpenBurger();
                  }}
                >
                  {link.name}

                  {link.name === activeSection && (
                    <motion.span
                      aria-hidden="true"
                      className={styles.navLinkBg}
                      layoutId="activeSection"
                      transition={{
                        type: "spring",
                        stiffness: 380,
                        damping: 40,
                      }}
                    />
                  )}
                </Link>
              </motion.li>
            );
          })}
        </ul>

        <DiscussButton className={styles.headerMobileDiscussButton}>
          Обсудить проект
        </DiscussButton>
      </motion.nav>
      <DiscussButton className={styles.headerDiscussButton}>
        Обсудить проект
      </DiscussButton>
      <motion.button
        type="button"
        className={`${styles.burgerButton} ${
          openedBurger ? styles.active : ""
        }`}
        onClick={handleOpenBurger}
        aria-label={
          openedBurger ? "Open navigation drawer" : "Close navigation drawer"
        }
      />
    </motion.header>
  );
}
